export default function showPeriod(period){
    var label=""
    switch(period){
            case 0:
                label="7:30"
                break
            case 1:
                label="8:30"
                break
            case 2:
                label="9:30"
                break
            case 3:
                label="10:30"
                break
            case 4:
                label="11:30"
                break
            case 5:
                label="12:30"
                break
            case 6:
                label="13:30"
                break
            case 7:
                label="14:30"
                break
            case 8:
                label="15:30"
                break
            case 9:
                label="16:30"
                break
            case 10:
                label="17:30"
                break
            case 11:
                label="18:30"
                break
        }
        return label
}